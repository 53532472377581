import ui from './ui'
import auth from './auth'
import menu from './menu'
import statistics from './statistics'
import product from './product'
import moduleAccess from './moduleAccess'

export default {
  ...ui,
  ...auth,
  ...menu,
  ...statistics,
  ...product,
  ...moduleAccess
}
