import GetMenu from '@/graphql/Menu.gql'
import { createProvider } from '../../vue-apollo'

const apollo = createProvider()

export default {
  async getMenu ({ dispatch }) {
    try {
      const result = await apollo.defaultClient.query({
        query: GetMenu,
        fetchPolicy: 'no-cache'
      })
      return result.data.Menu
    } catch (error) {
      dispatch('showError', error)
    }
  }
}
