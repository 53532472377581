
export default {
  title: 'hello Intelligential',
  register: {
    required: 'Required',
    registerButton: 'Register',
    registerTitle: 'Sign up',
    seePassword: 'See passwords',
    legalNameLabel: 'Legal name',
    rfcLabel: 'RFC',
    emailLabel: 'Contact email',
    contactNameLabel: 'Name',
    contactLastNameLabel: 'Last name',
    passwordLabel: 'Password',
    confirmPasswordLabel: 'Confirm your password',
    legalNamePlaceholder: 'Enter your legal name',
    rfcPlaceholder: 'Enter your RFC',
    emailPlaceholder: 'Enter your contact email',
    contactNamePlaceholder: 'Enter your name',
    contactLastNamePlaceholder: 'Enter your last name',
    legalNameDataVV: 'Legal name',
    rfcDataVV: 'RFC',
    emailDataVV: 'Email',
    contactNameDataVV: 'Name',
    contactLastNameDataVV: 'Last name',
    passwordDataVV: 'Password',
    confirmPasswordDataVV: 'Confirm'
  },
  steps: {
    welcome: 'Welcome to Intelligential',
    products: 'Products',
    pipeline: 'Pipeline',
    forms: 'Forms',
    configurationPipeline: 'Pipeline configuration',
    confirmation: 'Confirmation',
    step: 'Step {stepNumber}',
    nextStep: 'Next',
    beforeStep: 'Before',
    step1: {
      title: 'Register your first product',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel ex tortor. Vestibulum at nibh ut massa consectetur feugiat aliquet sit amet massa. In et purus a sem lacinia placerat. Fusce vel iaculis odio. Nam lacinia efficitur volutpat. In dapibus eros ac bibendum pulvinar. Nullam commodo pulvinar facilisis. Fusce bibendum enim at tortor pharetra posuere.',
      configure: 'Configure your products',
      recommended: 'Recommended products',
      amounts: 'Amounts',
      columnsTable: {
        name: 'Name',
        creditType: 'Credit Type',
        interest: 'Interest',
        collectionType: 'Collection Type',
        collectionPeriod: 'Collection Period',
        detail: 'Detail',
        empty: 'Without products',
        status: 'Status',
        actions: 'Actions',
        minAmount: 'Min Amount',
        maxAmount: 'Max Amount'
      }
    },
    step2: {
      title: 'Create the first pipeline for {productName}',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel ex tortor. Vestibulum at nibh ut massa consectetur feugiat aliquet sit amet massa. In et purus a sem lacinia placerat. Fusce vel iaculis odio. Nam lacinia efficitur volutpat. In dapibus eros ac bibendum pulvinar. Nullam commodo pulvinar facilisis. Fusce bibendum enim at tortor pharetra posuere.',
      newFlow: 'New flow config',
      titleMessage: 'Pipelines configurations',
      returnStep: 'Return flow list',
      confirm: 'Confirm',
      addStep: 'Add step',
      flowModelTitle: 'Business flow',
      productLabel: 'Product:',
      pipelineLabel: 'Add new pipeline',
      pipelineNameLabel: 'Pipeline name',
      pipelineNamePlaceholder: 'Enter the pipeline name',
      pipelineDescriptionLabel: 'Description',
      pipelineDescriptionPlaceholder: 'Enter  short description',
      addButtonPipelineInfo: 'Add',
      withoutPipelineMessage: 'First add the flow at the top'
    },
    step3: {
      title: 'Forms for your product {productName}',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel ex tortor. Vestibulum at nibh ut massa consectetur feugiat aliquet sit amet massa. In et purus a sem lacinia placerat. Fusce vel iaculis odio. Nam lacinia efficitur volutpat. In dapibus eros ac bibendum pulvinar. Nullam commodo pulvinar facilisis. Fusce bibendum enim at tortor pharetra posuere.'
    },
    step4: {
      title: 'Configuration summary',
      messageCongratulations: 'Finished the configuration successfully'
    }
  },
  pipelines: {
    titles: {
      list: 'Pipelines',
      add: 'New pipeline',
      edit: 'Modify {pipelineName}',
      flows: 'Flows'
    },
    buttons: {
      add: 'New Pipeline',
      save: 'Save'
    },
    forms: {
      pipelineName: 'Flow name',
      description: 'Description',
      flows: 'Flow editor'
    },
    tables: {
      flowName: 'Flow name',
      description: 'Descripción',
      createdBy: 'Created by',
      createdAt: 'Created at',
      actions: 'Actions',
      preconfigured: 'Preconfigured',
      system: 'System',
      select: 'Select',
      empty: 'Without pipelines'
    }
  },
  paymentDetail: {
    principalBalance: 'Principal balance',
    interestBalance: 'Interest balance',
    interestTaxBalance: 'Interest tax balance',
    defaultInterestBalance: 'Default interest balance',
    defaultInterestTaxBalance: 'Default interest tax balance',
    insuranceBalance: 'Insurance balance',
    insuranceTaxBalance: 'Insurance tax balance',
    commissionBalance: 'Commission balance',
    commissionTaxBalance: 'Commission tax balance'
  }
}
