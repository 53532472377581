export default {
  'en-US': {
    currency: {
      style: 'currency', currency: 'USD'
    }
  },
  'es-MX': {
    currency: {
      style: 'currency', currency: 'MXN'
    }
  },
  'pt-BR': {
    currency: {
      style: 'currency', currency: 'BRL'
    }
  },
  'ja-JP': {
    currency: {
      style: 'currency', currency: 'JPY', currencyDisplay: 'symbol'
    }
  }
}
