import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(route => route.meta.auth)
  const authed = localStorage.getItem('access-token')

  const signInObject = { name: 'login', query: { redirect: to.fullPath } }
  if (requiresAuth && !authed) next(signInObject)

  if (requiresAuth && authed) {
    const isValidSession = await store.dispatch('validateSession')
    if (isValidSession) {
      next()
    } else {
      await store.dispatch('logout')
    }
  } else if (!requiresAuth && authed) {
    next()
  }
  next()
})

export default router
