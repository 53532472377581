import GetProducts from '@/graphql/Products/GetProducts.gql'
import { createProvider } from '@/vue-apollo'

const apollo = createProvider()

export default {
  async getProducts ({ commit, dispatch }, { pageNumber, perPage }) {
    try {
      const params = {
        pageNumber,
        perPage
      }
      const { data } = await apollo.defaultClient.query({
        query: GetProducts,
        fetchPolicy: 'no-cache',
        variables: params
      })
      return data.Products
    } catch (error) {
      dispatch('showError', error)
    }
  }
}
