import GetTotalContracts from '@/graphql/Statistics/GetTotalContracts.gql'
import { createProvider } from '../../../vue-apollo'

const apollo = createProvider()

export default {
  async getTotalContracts ({ dispatch }) {
    try {
      const result = await apollo.defaultClient.query({
        query: GetTotalContracts,
        fetchPolicy: 'no-cache'
      })

      const megaContracts = result.data.getContractSets.pagination.total
      const normalContracts = result.data.Contracts.pagination.total
      const totalContracts = normalContracts + megaContracts
      return totalContracts
    } catch (error) {
      dispatch('showError', error)
    }
  }
}
