import GetApplicationsCount from '@/graphql/Statistics/GetApplicationsCount.gql'
import { createProvider } from '../../../vue-apollo'

const apollo = createProvider()

export default {
  async getApplicationsCount ({ dispatch }, { productId }) {
    try {
      const variables = {}
      if (productId) variables.productId = productId
      const result = await apollo.defaultClient.query({
        query: GetApplicationsCount,
        fetchPolicy: 'no-cache',
        variables
      })

      return result.data.getApplicationsCountByProduct
    } catch (error) {
      dispatch('showError', error)
    }
  }
}
