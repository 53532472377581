import ModuleAccess from '@/graphql/ModuleAccess.gql'
import { createProvider } from '../../vue-apollo'

const apollo = createProvider()

export default {
  async getModuleAccess ({ dispatch }, { module }) {
    try {
      const result = await apollo.defaultClient.query({
        query: ModuleAccess,
        fetchPolicy: 'no-cache',
        variables: {
          module
        }
      })

      return result.data.ModuleAccess
    } catch (error) {
      dispatch('showError', error)
    }
  }
}
