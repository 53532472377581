import GetCustomers from '@/graphql/Customers/GetCustomers.gql'
import GetTotalCustomers from '@/graphql/Statistics/GetTotalCustomers.gql'
import GetUserNotifications from '@/graphql/Customers/GetUserNotifications.gql'
import UpdateNotification from '@/graphql/Customers/UpdateNotification.gql'
import { createProvider } from '../../../vue-apollo'

const apollo = createProvider()

export default {
  async getCustomers ({ dispatch }, { pageNumber, perPage }) {
    try {
      const result = await apollo.defaultClient.query({
        query: GetCustomers,
        fetchPolicy: 'no-cache',
        variables: {
          pageNumber,
          perPage
        }
      })
      return result.data.Customers.customers
    } catch (error) {
      dispatch('showError', error)
    }
  },
  async getTotalCustomers ({ dispatch }, { customerType }) {
    try {
      const result = await apollo.defaultClient.query({
        query: GetTotalCustomers,
        fetchPolicy: 'no-cache',
        variables: {
          customerType
        }
      })
      return result.data.Customers.pagination.total
    } catch (error) {
      dispatch('showError', error)
    }
  },
  async getUserNotifications ({ dispatch }) {
    try {
      const result = await apollo.defaultClient.query({
        fetchPolicy: 'no-cache',
        query: GetUserNotifications
      })
      return result.data.UserNotifications
    } catch (error) {
      dispatch('showError', error)
    }
  },
  async updateNotification ({ dispatch }, notificationIndex) {
    try {
      const result = await apollo.defaultClient.mutate({
        mutation: UpdateNotification,
        fetchPolicy: 'no-cache',
        variables: {
          notificationIndex
        }
      })
      return result.data.updateNotification
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.graphQLErrors[0].message })
    }
  }
}
