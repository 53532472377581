<template>
  <div id="app">
    <TheNotifications />
    <router-view/>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import jwtDecode from 'jwt-decode'

export default {
  components: {
    TheNotifications: () => import('@/components/ui/TheNotifications.vue')
  },
  head () {
    return {
      title: 'My page title',
      meta: [
        { name: 'description', content: 'My page description' }
      ]
    }
  },
  methods: {
    ...mapMutations(['loginSuccess']),
    islogged: function () {
      const accessToken = localStorage.getItem('access-token')
      if (accessToken) {
        const payload = jwtDecode(accessToken)
        this.loginSuccess({
          ...payload,
          token: accessToken
        })
      }
    }
  },
  mounted () {
    this.islogged()
  }
}
</script>
