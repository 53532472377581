
import customers from './customers'
import contracts from './contracts'
import applications from './applications'

export default {
  ...customers,
  ...contracts,
  ...applications
}
