import router from '@/router'
import jwtDecode from 'jwt-decode'
import logIn from '@/graphql/Login.gql'
import resetPassword from '@/graphql/ResetPassword.gql'
import forgetPassword from '@/graphql/ForgetPassword.gql'
import changePassword from '@/graphql/ChangePassword.gql'
import validateSessionQuery from '@/graphql/ValidateSession.gql'
import profileMeQuery from '@/graphql/ProfileMe.gql'
import { onLogin, onLogout, createProvider } from '../../vue-apollo'

const apollo = createProvider()

export default {
  async signin ({ commit, dispatch }, { email, password, ip }) {
    try {
      const result = await apollo.defaultClient.query({
        query: logIn,
        variables: {
          email,
          password,
          ip
        }
      })
      const payload = jwtDecode(result.data.logIn)
      await onLogin(apollo.defaultClient, result.data.logIn)
      commit('loginSuccess', { ...payload })

      router.replace({ name: 'home' })
    } catch (error) {
      return { error: true, message: error.graphQLErrors[0].message }
    }
  },

  async logout ({ commit }) {
    await onLogout(apollo.defaultClient)
    router.replace({ name: 'login' })
  },

  async validateSession ({ dispatch }) {
    try {
      await apollo.defaultClient.query({
        query: validateSessionQuery,
        fetchPolicy: 'no-cache'
      })
      return true
    } catch (error) {
      dispatch('showError', error)
    }
  },

  async profileMe ({ dispatch, commit }) {
    try {
      const result = await apollo.defaultClient.query({
        query: profileMeQuery,
        fetchPolicy: 'no-cache'
      })
      commit('addUserProfile', result.data.me)
      return result.data.me
    } catch (error) {
      dispatch('logout')
      dispatch('showError', error)
    }
  },

  async changePass ({ dispatch }, { oldPassword, newPassword }) {
    try {
      const result = await apollo.defaultClient.mutate({
        mutation: changePassword,
        variables: {
          oldPassword: oldPassword,
          newPassword: newPassword
        }
      })
      dispatch('addTemporalNotification', {
        type: 'success',
        message: '¡Contraseña actualizada con éxito!'
      })
      return result
    } catch (error) {
      dispatch('showError', error)
    }
  },

  async resetPass ({ dispatch }, { token, password }) {
    try {
      const result = await apollo.defaultClient.mutate({
        mutation: resetPassword,
        variables: {
          token: token,
          password: password
        }
      })
      dispatch('addTemporalNotification', {
        type: 'success',
        message: 'La contraseña se ha restablecido correctamente'
      })
      return result
    } catch (error) {
      dispatch('showError', error)
    }
  },

  async forgetPass ({ dispatch }, { email }) {
    try {
      const result = await apollo.defaultClient.mutate({
        mutation: forgetPassword,
        variables: {
          email: email
        }
      })
      dispatch('addTemporalNotification', {
        type: 'success',
        message:
          'Se ha enviado exitosamente el correo con las instrucciones para el cambio de contraseña'
      })
      return result
    } catch (error) {
      dispatch('showError', error)
    }
  }
}
