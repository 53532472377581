
export default {
  title: 'hola Intelligential',
  register: {
    required: 'Requerido',
    registerButton: 'Registrar',
    registerTitle: 'Registrate',
    seePassword: 'Ver contraseñas',
    legalNameLabel: 'Razón social',
    rfcLabel: 'RFC',
    emailLabel: 'Correo de contacto',
    contactNameLabel: 'Nombre',
    contactLastNameLabel: 'Apellidos',
    passwordLabel: 'Contraseña',
    confirmPasswordLabel: 'Confirma tu contraseña',
    legalNamePlaceholder: 'Introduce la razón social de tu empresa',
    rfcPlaceholder: 'Introduce el RFC de tu empresa',
    emailPlaceholder: 'Introduce tu correo electrónico',
    contactNamePlaceholder: 'Introduce tu nombre',
    contactLastNamePlaceholder: 'Introduce tus apellidos',
    legalNameDataVV: 'Razón social',
    rfcDataVV: 'RFC',
    emailDataVV: 'Correo',
    contactNameDataVV: 'Nombre',
    contactLastNameDataVV: 'Apellidos',
    passwordDataVV: 'Contraseña',
    confirmPasswordDataVV: 'de confirmación',
    contractNumber: 'No. de contrato',
    contractNumberPlaceholder: 'Introduce tu no. de contrato',
    contractNumberDataVV: 'No. de contrato'
  },
  roles: {
    admin: 'Administrador',
    analyst: 'Analista',
    compliance: 'Oficial de cumplimiento',
    manager: 'Manager',
    viewer: 'Revisor',
    custom: 'Personalizado'
  },
  steps: {
    welcome: 'Bienvenido a Intelligential',
    products: 'Productos',
    pipeline: 'Flujos',
    forms: 'Formularios',
    configurationPipeline: 'Configuración del pipeline de análisis',
    confirmation: 'Confirmación',
    step: 'Paso {stepNumber}',
    nextStep: 'Siguiente',
    beforeStep: 'Anterior',
    step1: {
      title: 'Registra tu primer producto',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel ex tortor. Vestibulum at nibh ut massa consectetur feugiat aliquet sit amet massa. In et purus a sem lacinia placerat. Fusce vel iaculis odio. Nam lacinia efficitur volutpat. In dapibus eros ac bibendum pulvinar. Nullam commodo pulvinar facilisis. Fusce bibendum enim at tortor pharetra posuere.',
      configure: 'Configura tus productos',
      recommended: 'Productos recomendados',
      amounts: 'Montos',
      columnsTable: {
        name: 'Nombre',
        creditType: 'Tipo de crédito',
        interest: 'Cálculo de interés',
        collectionType: 'Tipo de cobro',
        collectionPeriod: 'Periodo de cobro',
        detail: 'Detalle',
        empty: 'Sin productos',
        status: 'Estatus',
        actions: 'Acciones',
        minAmount: 'Monto mínimo',
        maxAmount: 'Monto máximo'
      }
    },
    step2: {
      title: 'Crea el primer flujo de trabajo para {productName}',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel ex tortor. Vestibulum at nibh ut massa consectetur feugiat aliquet sit amet massa. In et purus a sem lacinia placerat. Fusce vel iaculis odio. Nam lacinia efficitur volutpat. In dapibus eros ac bibendum pulvinar. Nullam commodo pulvinar facilisis. Fusce bibendum enim at tortor pharetra posuere.',
      newFlow: 'Configura nuevo pipeline',
      titleMessage: 'Configura tus pipelines',
      returnStep: 'Regresar a la lista de pipelines',
      confirm: 'Confirmar',
      addStep: 'Añadir nueva etapa',
      flowModelTitle: 'Pipeline de negocio',
      productLabel: 'Producto:',
      pipelineLabel: 'Agregar flujo',
      pipelineNameLabel: 'Nombre del flujo',
      pipelineNamePlaceholder: 'Ingresa el nombre del flujo',
      pipelineDescriptionLabel: 'Descripción',
      pipelineDescriptionPlaceholder: 'Ingresa una breve descripción',
      addButtonPipelineInfo: 'Agregar',
      withoutPipelineMessage: 'Primero agrega el flujo en la parte superior',
      legalNameLabel: 'Nombre del documento legal'
    },
    step3: {
      title: 'Formularios para tu producto {productName}',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel ex tortor. Vestibulum at nibh ut massa consectetur feugiat aliquet sit amet massa. In et purus a sem lacinia placerat. Fusce vel iaculis odio. Nam lacinia efficitur volutpat. In dapibus eros ac bibendum pulvinar. Nullam commodo pulvinar facilisis. Fusce bibendum enim at tortor pharetra posuere.'
    },
    step4: {
      title: 'Resumen de configuración',
      messageCongratulations: 'Haz finalizado la configuración con éxito'
    }
  },
  pipelines: {
    titles: {
      list: 'Mis procesos de análisis',
      add: 'Agregar pipeline',
      edit: 'Editar {pipelineName}',
      flows: 'Pipelines'
    },
    buttons: {
      add: 'Nuevo pipeline',
      save: 'Guardar'
    },
    forms: {
      pipelineName: 'Nombre del pipeline',
      description: 'Descripción',
      flows: 'Edición del pipeline'
    },
    tables: {
      flowName: 'Nombre del pipeline',
      description: 'Descripción',
      createdBy: 'Creado por',
      createdAt: 'Fecha de creación',
      actions: 'Acciones',
      preconfigured: 'Pre configurado',
      system: 'Sistema',
      select: 'Seleccionar',
      empty: 'Sin pipelines'
    }
  },
  configuration: {
    titlePage: 'Configuración',
    commissions: {
      title: 'Comisiones'
    },
    prelation: {
      title: 'Prelación de pagos',
      commission: 'Comisiones',
      commissionTax: 'IVA de comisiones',
      insurance: 'Seguro',
      defaultInterest: 'Interés moratorio',
      interest: 'Interés',
      principal: 'Capital',
      interestTax: 'IVA del interés',
      insuranceTax: 'IVA del seguro',
      defaultInterestTax: 'IVA del interés moratorio',
      interestOrder: 'Interés de orden',
      interestOrderTax: 'Iva de interés de orden'
    }
  },
  paymentDetail: {
    principalBalance: 'Capital',
    interestBalance: 'Intereses',
    interestTaxBalance: 'IVA del interes',
    defaultInterestBalance: 'Intereses moratorio',
    defaultInterestTaxBalance: 'IVA de intereses moratorio',
    insuranceBalance: 'Seguro',
    insuranceTaxBalance: 'IVA del seguro',
    commissionBalance: 'Comisiones',
    commissionTaxBalance: 'IVA de comisiones'
  }
}
