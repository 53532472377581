const routes = [
  {
    path: '/',
    component: () => import('@/views/Home.vue'),
    meta: {
      auth: true
    },
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/Dashboard.vue'),
        meta: { gtm: 'home' }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: { gtm: 'login' }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: { gtm: 'forgot-password' }
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import('@/views/ChangePassword.vue'),
    meta: { gtm: 'change-password' }
  },
  {
    path: '/reset-password/:token',
    props: true,
    name: 'reset-password',
    component: () => import('@/views/ResetPassword.vue'),
    meta: { gtm: 'reset-password' }
  },
  {
    path: '*',
    name: 'errorPage',
    component: () => import('@/views/Error404.vue'),
    meta: { gtm: 'home' }
  }
]
export default routes
