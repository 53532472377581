import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueI18n from 'vue-i18n'
import messages from '@/langs'
import dateTimeFormats from '@/langs/dateTimeFormats'
import numberFormats from '@/langs/numberFormats'
import VueGtm from 'vue-gtm'
import './styles/global-style.scss'
import { createProvider } from './vue-apollo'

const GTM_ID = process.env.VUE_APP_MTG_ID

Vue.config.productionTip = false
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'es',
  messages,
  dateTimeFormats,
  numberFormats
})

new Vue({
  router,
  store,
  i18n,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')

Vue.use(VueGtm, {
  id: GTM_ID,
  queryParams: {
    gtm_auth: '1AogVw_dBK_vfmbm4gDdhA',
    gtm_preview: 'env-1',
    gtm_cookies_win: 'x'
  },
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router
})
