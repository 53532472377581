import router from './../../router'

const DEFAULT_DELAY = 5000

export default {
  returnPrevView () {
    router.go(-1)
  },

  addTemporalNotification ({ commit }, payload) {
    const now = new Date()
    commit('addNotification', { ...payload, datetime: now })
    const delay = payload.delay || DEFAULT_DELAY
    setTimeout(() => commit('deleteNotification', now), delay)
  },
  showError ({ dispatch }, error) {
    if (error.graphQLErrors.length) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.graphQLErrors[0].message })
    } else {
      dispatch('addTemporalNotification', { type: 'danger', message: 'Ha ocurrido un error, intente más tarde o contacte al administrador' })
    }
  }
}
